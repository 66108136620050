import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "answer", "correctAnswer", "questions", "question" ]
  static values = { index: Number }

  connect() {
    this.showCurrentQuestion()
  }

  ///////////////
  // QUESTIONS //
  ///////////////

  check() {
    if (this.answer == null)
      console.log('No answer selected')
    else if (this.answer == this.correctAnswer) {
      this.showCorrectMessage()
      this.next()
    } else
        this.showWrongMessage()
  }

  next() {
    this.indexValue++

    if (this.questionTargets.length > (this.indexValue))
      this.showCurrentQuestion()
    else {
      this.questions.hidden = true
      this.dispatch("next")
    }
  }

  showCurrentQuestion() {
    this.hideMessages()
    this.questionTargets.forEach((element, index) => {
      element.hidden = index != this.indexValue
    })
  }

  //////////////
  // MESSAGES //
  //////////////

  showCorrectMessage() {
    this.changeHiddenMessageProperty("#wrong-message", true)
    this.changeHiddenMessageProperty("#correct-message", false)
  }

  showWrongMessage() {
    this.changeHiddenMessageProperty("#correct-message", true)
    this.changeHiddenMessageProperty("#wrong-message", false)
  }

  hideMessages() {
    this.changeHiddenMessageProperty("#correct-message", true)
    this.changeHiddenMessageProperty("#wrong-message", true)
  }

  changeHiddenMessageProperty(messageId, value) {
    document.querySelectorAll(messageId).forEach((element, index) => {element.hidden = value})
  }

  /////////////
  // GETTERS //
  /////////////

  get answer() {
    let selected_answer = null

    this.answerTargets.forEach((el, i) => { if (el.checked) { selected_answer = el.value } })

    return selected_answer
  }

  get correctAnswer() {
    return this.correctAnswerTargets[this.indexValue].value
  }

  get questions() {
    return this.questionsTargets[0]
  }
}