import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['link']

  connect() {
    this.link.hidden = true
  }

  show() {
    this.link.hidden = false
    console.log("show next learning_item link!")
  }

  get link() {
    return this.linkTargets[0]
  }
}
